html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  overflow: hidden;
  overflow-y: auto;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.css-14jqv7c-MuiContainer-root {
  padding: 0 !important;
}

.slick-dots li button:before {
  font-size: 20px !important;
}

.slick-slider {
  overflow: hidden;
}

.slick-slide {
  overflow: hidden;
}

.slick-dots {
  position: absolute;
  bottom: 60px !important;
  left: 50px !important;
  display: flex !important;
}

.slick-dots li {
  height: 20px;
  width: 20px;
}

.slick-dots li.slick-active {
  width: 50px;
}

.slick-dots li .ft-slick__dots--custom {
  height: 20px;
  width: 20px;
  background-color: #e5e7e9;
  border-radius: 50px;
  margin: 0 5px;
}

.slick-dots li.slick-active .ft-slick__dots--custom {
  width: 50px;
  margin-right: 15px !important;
  background-color: rgb(104, 0, 215) !important;
  right: auto;
  left: auto;
  z-index: 1;
}



.custom-flex-container {
  height: 100%;
  justify-content: space-evenly;
  gap: 10px
}

.hero-grid .arrow[data-v-e6d54386]:first-child {
  animation-delay: 1s;
}

.hero-grid .arrow[data-v-e6d54386]:nth-child(2) {
  animation-delay: 1.5s;
}

@keyframes showArrow {
  0%,
  30% {
    width: 0;
  }

  to {
    width: 185px
  }
}

.benefit-grid:hover .MuiCard-root{
  transform: scale(1.05);
  transition: all 300ms;
}